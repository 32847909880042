'use strict';

// because fuck you, IE
NodeList.prototype.forEach = Array.prototype.forEach;

const bgSrcset = {
    bgSrcset: this,
    srcElement: document.querySelector('.bg-srcset'),
    bgContainer: document.querySelector('.bg-container'),
    src: '',
    update: function (e) {
        let src = typeof bgSrcset.srcElement.currentSrc !== 'undefined' ? bgSrcset.srcElement.currentSrc : bgSrcset.srcElement.src;

        if (bgSrcset.src !== src) {
            bgSrcset.src = src;
            bgSrcset.bgContainer.style.backgroundImage = 'url("' + bgSrcset.src + '")';
            bgSrcset.bgContainer.classList.add('loaded');
        }
    },
    init: function () {
        if (bgSrcset.srcElement) {
            bgSrcset.srcElement.addEventListener('load', function (e) {
                bgSrcset.update(e)
            });
            window.addEventListener('resize', function (e) {
                bgSrcset.update(e)
            });

            if (bgSrcset.srcElement.complete) {
                bgSrcset.update();
            }
        }
    },
};

bgSrcset.init();

const lazyImages = {
    lazyImages: this,
    supportsSrcset: typeof new Image().currentSrc === 'string',
    load: function (img) {
        if (!img.classList.contains('loaded')) {
            img.addEventListener('load', function () {
                img.classList.add('loaded');
            });

            if (lazyImages.supportsSrcset) {
                img.srcset = img.dataset.srcset;
            } else {
                img.src = img.dataset.src;
            }
        }
    },
    loadVisible: function () {
        const foundImages = document.querySelectorAll('img[data-src]:not(.loaded)');
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        foundImages.forEach(function (img) {
            const clientRect = img.getBoundingClientRect();
            const inView = img.clientWidth > 0 && (clientRect.left <= windowWidth && clientRect.right >= 0) && (clientRect.top <= windowHeight && clientRect.bottom >= 0);

            if (inView) {
                lazyImages.load(img);
            }
        });
    }
};

window.document.addEventListener('scroll', function() {
    window.requestAnimationFrame(lazyImages.loadVisible);
});

window.document.addEventListener('resize', function() {
    window.requestAnimationFrame(lazyImages.loadVisible);
});

lazyImages.loadVisible();

function Lightbox(lightboxElement) {
    this.lightboxContainer = lightboxElement;
    this.lightboxList = lightboxElement.querySelector('.js-lightbox-items') || false;
    this.videoElement = lightboxElement.querySelector('.lightbox__iframe-container iframe') || false;
    this.active = false;

    // only applies for lightbox with multiple items
    if (this.lightboxList) {
        this.currentSlide = 0;
        this.prevButton = this.lightboxContainer.querySelector('.js-lightbox-prev');
        this.nextButton = this.lightboxContainer.querySelector('.js-lightbox-next');
        this.numSlides = this.lightboxList.childElementCount;
        this.lightboxList.appendChild(this.lightboxList.firstElementChild.cloneNode(true));
        this.lightboxList.lastElementChild.dataset.item = this.numSlides;
    }

    this.open = function () {
        this.lightboxContainer.classList.remove('closed');
        if(this.videoElement) {
            this.videoElement.setAttribute('src', this.videoElement.dataset.src);
        }
        this.active = true;
    };

    this.close = function () {
        if(this.videoElement) {
            this.videoElement.setAttribute('src', '');
        }
        this.lightboxContainer.classList.add('closed');
        this.active = false;        
    };

    this.moveTo = function (elementNumber) {
        if (this.active && this.lightboxList) {
            this.lightboxContainer.classList.remove('closed');
            this.lightboxList.style.transform = 'translateX(' + (elementNumber * -100) + 'vw)';
            this.currentSlide = Math.max(0, Math.min(this.numSlides, parseInt(elementNumber, 10)));
            const imgToLoad = this.lightboxList.querySelector('li:nth-child(' + (this.currentSlide + 1) + ') img:not(.loaded)');

            if (imgToLoad) {
                lazyImages.load(imgToLoad);
            }

            window.requestAnimationFrame(function () {
                this.lightboxList.classList.remove('no-transition');
            }.bind(this));
        }
    };

    this.jumpTo = function (elementNumber) {
        if (this.active && this.lightboxList) {
            this.lightboxList.classList.add('no-transition');
            window.requestAnimationFrame(function () {
                this.moveTo(elementNumber);
            }.bind(this));
        }
    };

    this.prev = function () {
        if (this.active && this.lightboxList) {
            if (this.currentSlide === 0) {
                this.jumpTo(this.numSlides);
            }
            window.requestAnimationFrame(function () {
                window.requestAnimationFrame(function () {
                    window.requestAnimationFrame(function () {
                        this.currentSlide--;
                        this.moveTo(this.currentSlide);
                    }.bind(this));
                }.bind(this));
            }.bind(this));
        }
    };

    this.next = function () {
        if (this.active && this.lightboxList) {
            if (this.currentSlide === this.numSlides) {
                this.jumpTo(0);
            }
            window.requestAnimationFrame(function () {
                window.requestAnimationFrame(function () {
                    window.requestAnimationFrame(function () {
                        this.currentSlide++;
                        this.moveTo(this.currentSlide);
                    }.bind(this));
                }.bind(this));
            }.bind(this));
        }
    };

    this.beginSwipe = function (event) {
        this.swipeStart = Math.round(event.changedTouches[0].screenX / 100);
    };

    this.endSwipe = function (event) {
        this.swipeEnd = Math.round(event.changedTouches[0].screenX / 100);

        if (this.swipeStart !== null && this.swipeEnd !== null) {
            if (this.swipeStart > this.swipeEnd) {
                this.next();
            } else if (this.swipeStart < this.swipeEnd) {
                this.prev();
            }
        }
    };

    // event listeners
    this.lightboxContainer.addEventListener('click', function (e) {
        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'IMG') {
            this.close();
        }
    }.bind(this));

    if (this.lightboxList) {
        this.prevButton.addEventListener('click', function () {
            this.prev();
        }.bind(this));
        this.nextButton.addEventListener('click', function () {
            this.next();
        }.bind(this));
        this.lightboxContainer.addEventListener('touchstart', function (e) {
            this.beginSwipe(e);
        }.bind(this));
        this.lightboxContainer.addEventListener('touchend', function (e) {
            this.endSwipe(e);
        }.bind(this));
        this.lightboxContainer.addEventListener('touchstart', function (e) {
            this.beginSwipe(e);
        }.bind(this));
        this.lightboxContainer.addEventListener('touchend', function (e) {
            this.endSwipe(e);
        }.bind(this));
    }

    window.addEventListener('keydown', function (e) {
        switch (e.key) {
            case "ArrowLeft":
            case "Left":
                this.prev();
                break;
            case "ArrowRight":
            case "Right":
                this.next();
                break;
            case "Escape":
            case "Esc":
                this.close();
                break;
        }
    }.bind(this));

}

const gallery = {
    gallery: this,
    lightbox: null,
    thumbnails: document.querySelectorAll('.performance__gallery-button'),
    init: function (alb) {
        gallery.lightbox = alb;
        gallery.thumbnails.forEach(function (thumbnail) {
            thumbnail.addEventListener('click', function () {
                const imgToLoad = document.querySelector('.' + thumbnail.dataset.galleryImg + ':not(.loaded)');
                const lightboxToFocus = thumbnail.dataset.item;
                gallery.lightbox.open();
                gallery.lightbox.jumpTo(lightboxToFocus);
            });
        });
    }
};




if (document.querySelector('.js-lightbox')) {
    const photoLightbox = new Lightbox(document.querySelector('.js-lightbox'));
    gallery.init(photoLightbox);
}

if (document.querySelector('.performance__video')) {
    const videoLightbox = new Lightbox(document.querySelector('.performance__video'));
    document.querySelector('.performance__video-button').addEventListener('click', function () {
        videoLightbox.open();
    });
}



// menu toggle

const menuButton = document.querySelector('.js-nav-toggle');
menuButton.addEventListener('click', function () {
    document.body.classList.toggle('menu-open');
});